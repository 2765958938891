import React from "react";
import PropTypes from "prop-types";
import Button from "../Button";

const Job = (props: any) => (
  <div className="openings-item">
    <div className="div-block-55">
      <h3 className="heading-2 white">{props.jobOpening}</h3>
      <p className="p2 white" style={{ padding: "none" }}>
        {props.location}
      </p>
    </div>
    <div className="div-block-56">
      <Button
        spacer={false}
        buttonClass="button-container-details"
        buttonHref={props.link}
        buttonHrefClass="button-details green w-button"
        buttonLabel="see details"
        buttonLine="button-box green"
      />
    </div>
  </div>
);

export default Job;

Job.propTypes = {
  jobOpening: PropTypes.string,
  location: PropTypes.string,
  link: PropTypes.string,
};
