import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import SEO from "../components/SEO";
import HeroContainer from "../components/HeroContainer";
import TaggedTextWithDescription from "../components/TaggedTextWithDescription";
import Benefit from "../components/Benefit";
import Job from "../components/Job";
import ApplicationForm from "../components/ApplicationForm";
import { AnimatedRandomX } from "../components/Animations";
import { AnimatedRandomX as AnimatedLineX } from "../components/ProcessStep";

const RandomBlockX = (props) => (
  <AnimatedRandomX {...props} initialPose="exit" pose="enter" />
);
const RandomLineX = (props) => (
  <AnimatedLineX {...props} initialPose="exit" pose="enter" />
);

export const pageQuery = graphql`
  query JobsQuery {
    prismicSeo(data: { name: { eq: "Mosano | Join Us" } }) {
      data {
        name
        title
        description
        keywords
        canonical_url {
          url
        }
        image {
          url
        }
      }
    }
    allPrismicJob {
      nodes {
        data {
          location
          title
        }
        uid
      }
    }
    bitmap: file(relativePath: { eq: "images/Bitmap.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 90
          tracedSVGOptions: {
            background: "#FCDA2A"
            color: "#444"
            threshold: 100
          }
          placeholder: BLURRED
          layout: FULL_WIDTH
        )
      }
    }
    bitmap2: prismicImage(uid: { eq: "join-us-1" }) {
      data {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 90
                tracedSVGOptions: {
                  background: "#FCDA2A"
                  color: "#444"
                  threshold: 100
                }
                placeholder: BLURRED
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
    prismicCookieConsent(uid: { eq: "cookie_consent" }) {
      data {
        setting_button_label
        accept_button_label
        decline_button_label
        close_button_label
        close_icon_alt
        title {
          text_1
          text_2
          text_3
        }
        cookies_categories {
          name
          details {
            text
          }
          policy_link {
            url
          }
          policy_link_text
          expand_button_label
          status
        }
      }
    }
  }
`;

const JoinUs = (props: any) => {
  const allJobs = props.data.allPrismicJob.nodes || [];
  const bitmap = props.data.bitmap.childImageSharp.gatsbyImageData || [];
  const bitmap2 =
    props.data.bitmap2.data.image.localFile.childImageSharp.gatsbyImageData ||
    [];

  return (
    <>
      <SEO {...props.data.prismicSeo.data} />
      <div className="hero-join-us">
        <HeroContainer heroType="join-us">
          <HeroContainer
            block
            wrapper="wrapper-join-us-hero"
            title="We do it better. Together."
            titleClass="large-2 white"
            paragraph="People are our power. We have a flat work environment with an open culture, where we grow stronger by joining everyone’s skills and knowledge."
            paragraphClass="p1 large-2 white"
          />
          <div className="back">
            <div className="div-block-49" />
          </div>
          <GatsbyImage
            image={bitmap2}
            className="background-image"
            style={{ position: "absolute" }}
            alt={"background-image"}
          />
        </HeroContainer>
        <RandomBlockX className="div-block-2" />
        <RandomBlockX className="div-block-1" />
      </div>
      <div className="our-secret">
        <TaggedTextWithDescription
          containerType="our-secret"
          subtitle="Our Secret"
          title="At Mosano we aim for cooperative, dynamic and high-motivated people."
          titleClass="center medium"
          body="We’re always looking for enthusiastic people who can speak their mind and teach us something new. If you want to be part of a team where you are expected to expand your knowledge, quickly integrate new projects, communicate with clients and partners, and embrace challenges as a team you’re at the right place."
          bodyClass="p2 large center"
        >
          <RandomLineX className="line-28" />
          <RandomLineX className="line-29" />
        </TaggedTextWithDescription>
      </div>
      <div className="benefits">
        <div className="back-benefits-1">
          <TaggedTextWithDescription
            containerType="benefits"
            subtitle="Our Benefits"
            title="Our team’s satisfaction is top priority."
            body="The best work happens because of happy people. We strive to keep our people happy by looking out for their interests."
            bodyClass="p2 small"
            capClass="cap-blue"
          />
        </div>
        <div className="responsive-benefits-container">
          <div className="back-benefits-2" />
          <div className="container-benefits-items">
            <div className="wrapper-items">
              <Benefit
                benefitType="casual"
                benefit="Casual Workplace"
                benefitDescription="Professional doesn’t mean restrictive. This goes way beyond casual wardrobe, including energy breaks, background music and lots of shared laughs."
              />
              <Benefit
                benefitType="speech"
                benefit="Speak your mind"
                benefitDescription="There’s no room for hierarchical procedures at our place. We promote everyone’s involvement in the decision-making processes, so that everyone has a say."
              />
              <Benefit
                benefitType="rocket"
                benefit="Grow fast"
                benefitDescription="The boring days are a thing of the past! Being part of a startup is challenging, that’s a fact. But it also comes with the opportunity to learn new skills in a fast paced environment."
              />
              <Benefit
                benefitType="equipment"
                benefit="Top-notch equipment"
                benefitDescription="We provide everyone all the tools they need to craft exceptional work. This includes a brand new kick-ass laptop with all the needed accessories as well as any other perk to get everyone going."
              />
              <Benefit
                benefitType="sofa"
                benefit="40 hour work week policy"
                benefitDescription="We value each other’s personal life, therefore there are no extra hours, or demand for more than 40 hours a week."
              />
              <Benefit
                benefitType="clock"
                benefit="Flexible hours"
                benefitDescription="We don’t work on a fixed schedule, people are allowed to enter and leave the company at the time that suits their best interests."
              />
              <Benefit
                benefitType="world"
                benefit="Work worldwide"
                benefitDescription="We collaborate remotely with international companies, ranging from the USA to Hong Kong."
              />
              <Benefit
                benefitType="snack"
                benefit="Water, coffee, tea and snacks"
                benefitDescription="In our office we have water, coffee, tea, fruit and some other snacks at disposition."
              />
            </div>
          </div>
        </div>
      </div>
      <div className="openings">
        <RandomBlockX className="div-block-3" />
        <RandomBlockX className="div-block-4">
          <br />
          <br />‍
        </RandomBlockX>
        <TaggedTextWithDescription
          containerType="openings"
          subtitle="Our Openings"
          title="Build your career at Mosano"
          titleClass="center"
          body="Join our team and work on cutting edge projects that push the boundaries of technology."
          bodyClass="p2 center"
        />
        <div className="container-openings-items">
          <div className="div-block-54" />
          {allJobs.map((item, index) => (
            <Job
              jobOpening={item.data.title}
              location={item.data.location}
              link={`/jobs/${item.uid}`}
              key={`job-${index}-${item.uid}`}
            />
          ))}
        </div>
      </div>
      <div className="application">
        <RandomBlockX className="div-block-6" />
        <div className="container-application">
          <TaggedTextWithDescription
            className="wrapper-application"
            containerType="application"
            title="Can’t find what you are looking for?"
            titleClass="full"
            body="Maybe we need you and we don’t know it yet. Apply below if you think that’s the case and we’ll get back to you."
            bodyClass="p1 full"
          >
            <div className="div-block-58" />
          </TaggedTextWithDescription>
          <div className="wrapper-image-application">
            <GatsbyImage
              image={bitmap}
              className="image-application"
              alt={"background-image"}
            />
          </div>
        </div>
        <div className="wrapper-form">
          <RandomBlockX className="div-block-5" />
          <div className="application-text1" />
          <ApplicationForm />
        </div>
      </div>
    </>
  );
};
export default JoinUs;
