import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

export const query = graphql`
  query {
    casual: file(relativePath: { eq: "images/casual.svg" }) {
      publicURL
    }
    speech: file(relativePath: { eq: "images/speech.svg" }) {
      publicURL
    }
    rocket: file(relativePath: { eq: "images/rocket.svg" }) {
      publicURL
    }
    equipment: file(relativePath: { eq: "images/equipment.svg" }) {
      publicURL
    }
    sofa: file(relativePath: { eq: "images/sofa.svg" }) {
      publicURL
    }
    clock: file(relativePath: { eq: "images/clock.svg" }) {
      publicURL
    }
    world: file(relativePath: { eq: "images/world.svg" }) {
      publicURL
    }
    snack: file(relativePath: { eq: "images/snack.svg" }) {
      publicURL
    }
  }
`;

const Benefit = (props: any) => {
  const data = useStaticQuery(query);
  return (
    <div className={`benefit-item${props.benefitOrientation || ""}`}>
      <img
        src={data[props.benefitType].publicURL}
        width="80"
        height="80"
        alt={`Mosano Benefit - ${props.benefit}`}
        className="benefit-icon"
      />
      <h2 className="heading-3 medium">{props.benefit}</h2>
      <p className="p2 medium">{props.benefitDescription}</p>
    </div>
  );
};

export default Benefit;

Benefit.propTypes = {
  benefitType: PropTypes.string,
  benefit: PropTypes.string,
  benefitDescription: PropTypes.string,
  benefitOrientation: PropTypes.string,
};
